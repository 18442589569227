document.addEventListener("turbolinks:load", () => {
  checkPaymentElement()
  const flowCard = document.querySelector('.flow-card');
  if (flowCard) {
    let observer = new MutationObserver(flowStatusChange);
    let options = { attributes: true };
    observer.observe(flowCard, options);
  }
});

async function checkPaymentElement() {
  const paymentElement = document.querySelector('#payment-element')
  if (paymentElement) {
    let intent = await paymentIntent()
    initPaymentStatus(intent)
  }
}

async function paymentIntent() {
  let element = document.querySelector('#payment-element')
  let result = await whisper("/booking_payments/" + element.dataset.paymentId + "/intent", {}, "Error fetching payment", "GET");
  return result.intent;
}

function flowStatusChange(mutations) {
  for (let mutation of mutations) {
    if (mutation.attributeName === 'data-status') {
      switch (mutation.target.dataset.status) {
        case "is_pending":
          checkPaymentElement()
          break;
        default:
          break;
      }
    }
  }
}

async function initPaymentStatus(intent) {
  switch (intent.status) {
    case "requires_payment_method":
      initPaymentElement(intent)
      break;
    case "succeeded":
      changeFlowContent("is_confirmed")
      // let element = document.querySelector('#payment-element')
      // let result = await whisper("/booking_payments/" + element.dataset.paymentId + "/fulfill", {}, "Error fetching payment", "POST");
      // if (result.success) {
      //   changeFlowContent("is_confirmed")
      //   break;
      // } else {
      //   notify_success("Processing...");
      //   break;
      // }
    case "processing":
      // Show processing
      notify_success("Processing...");
      break;
    default:
      // Show processing
      notify_danger("Something went wrong.");
      break;
  }
}

async function changeFlowContent(status) {
  const bookingSlug = document.querySelector('#payment-element').dataset.bookingSlug
  let result = await whisper("/bookings/" + bookingSlug + "/status/html", {status: status}, "Something went wrong", "GET");
  $('.flow-card').html(result.html);
}

function initStripe() {
  let element = document.querySelector('#payment-element')
  return Stripe(element.dataset.stripePublicKey, { stripeAccount: element.dataset.stripeAccount });
}

async function initPaymentElement(intent) {
  let stripe = initStripe()
  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#15202B',
      colorText: '#15202B',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      borderRadius: '2px',
    },
  };
  const options = { clientSecret: intent.client_secret, appearance };
  initialize(stripe, stripe.elements(options));
}

function initialize(stripe, elements) {

  // Init Page
  const submit_btn = document.getElementById('submit-payment');
  const loading_spinner = document.getElementById('payment-form-loader');
  const billing_address_element = document.getElementById('billing-address-element');
  const collect_address = (collect_billing_address()) ? 'never' : 'auto';

  // Init Payment Element
  const options = { fields: { billingDetails: { address: collect_address } } };
  const paymentElement = elements.create("payment", options);

  // Show messages based on time elapsed
  const message3Sec = document.getElementById('payment-form-loader__text--3sec');
  const message5Sec = document.getElementById('payment-form-loader__text--5sec');

  const show3SecMessage = setTimeout(() => {
    message3Sec.classList.remove('is-hidden');
  }, 3000);

  const show5SecMessage = setTimeout(() => {
    message3Sec.classList.add('is-hidden');
    message5Sec.classList.remove('is-hidden');
  }, 6000);

  // Generate & Load Payment Element
  try {
    paymentElement.mount("#payment-element");

    paymentElement.on('loaderstart', function (event) {
      loading_spinner.style.display = 'none';
      submit_btn.style.display = 'block';
      clearTimeout(show3SecMessage);
      clearTimeout(show5SecMessage);
    });

    paymentElement.on('ready', function (event) {
      if (billing_address_element) {
        billing_address_element.style.display = 'block';
      }
    });
  } catch (error) {
    console.error("Error mounting payment element:", error);
    message3Sec.classList.add('is-hidden');
    message5Sec.classList.remove('is-hidden');
  }


  // Handle Payment Form Submission
  document.getElementById('payment-form').addEventListener('submit', function (ev) {
    ev.preventDefault();

    setLoading(true);

    // Validate Billing Address if Required
    if (collect_billing_address()) {
      if (!validateBillingAddress()) return false;
    }

    verifyBookingIsPending(stripe, elements)

  });
};

async function verifyBookingIsPending(stripe, elements) {
  const bookingSlug = document.getElementById('payment-element').dataset.bookingSlug
  let result = await whisper("/bookings/" + bookingSlug + "/pending", {}, 'Booking Expired', 'GET')
  if (result.pending) {
    handleSubmit(stripe, elements);
  } else {
    $('.flow-card').html(result.html);
  }
};

// function to capture billing address in a hash
function validateBillingAddress() {
  var fields = document.querySelectorAll('.billing-address.required')
  var billing_address_valid = true;
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].value == '') {
      fields[i].classList.add('invalid');
      billing_address_valid = false;
    } else {
      fields[i].classList.remove('invalid');
    }
  }
  if (!billing_address_valid) {
    notify_danger('Billing Address required');
    setLoading(false);
  }
  return billing_address_valid;
}

async function update_billing_address() {
  if (collect_billing_address()) {
    let data = { address: getBillingAddress() }
    let result = await whisper("/bookings/" + document.getElementById('payment-element').dataset.bookingSlug + "/billing_address", data, 'Error')
    return result.success;
  } else {
    return true;
  }
};

// function to capture billing address in a hash
function collect_billing_address() {
  return $('#elements-form').data('billing-address')
}

// function to capture billing address in a hash
function getBillingAddress() {
  if (collect_billing_address()) {
    var billing_address = {
      line1: document.getElementById('address_line1').value,
      line2: document.getElementById('address_line2').value,
      city: document.getElementById('address_city').value,
      state: document.getElementById('address_state').value,
      country: document.getElementById('address_country').value,
      postal_code: document.getElementById('address_postal_code').value
    }
    return billing_address;
  } else {
    return null;
  }
}

async function handleSubmit(stripe, elements) {

  if (!update_billing_address()) {
    notify_danger("An error occurred updating your billing address");
    setLoading(false);
    return false
  }

  const paymentElement = document.getElementById('payment-element');
  const response = await stripe.confirmPayment({
    elements,
    confirmParams: {
      return_url: paymentElement.dataset.rootUrl + "bookings/" + paymentElement.dataset.bookingSlug,
      payment_method_data: {
        billing_details: {
          name: paymentElement.dataset.bookingName,
          email: paymentElement.dataset.bookingEmail,
          address: getBillingAddress()
        }
      }
    },
    redirect: 'if_required',
  });

  if (response.error) {
    notify_danger(response.error.message);
  } else {
    initPaymentStatus(response.paymentIntent)
  }

  setLoading(false);

}

// Show a spinner on payment submission
function setLoading(isLoading) {
  var submit_btn = document.getElementById('submit-payment');
  if (isLoading) {
    submit_btn.innerHTML = "Processing...";
    submit_btn.disabled = true;
  } else {
    submit_btn.innerHTML = "Pay";
    submit_btn.disabled = false;
  }
}
